<template>
	<div class="base-button" 
	@click="clickHandler"
	v-bind:class="{
		underline: underline,
		small: size == 'small',
	}"
	v-bind:style="{
		...styles
	}">
		{{title}}
	</div>
</template>

<script>
export default {
	name: 'Button',
	props: {
		title: {
			type: String,
			required: true,
		},
		styles: {
			type: Object,	
		},
		underline: {
			type: Boolean,
			default: false,
		},
		size: {
			type: String,
			default: "big"
		}
	},
	methods: {
		clickHandler(){
			this.$emit('click')
		}
	},
};
</script>

<style scoped>
.base-button{
	height: 32px;
	min-width: 110px;
	width: fit-content;

	background: #e7e7e7;
	border: 1px solid rgb(26, 26, 26);
	border-radius: 4px;

	display: flex;
	justify-content: center;
	align-items: center;

	padding: 7px 10px;

	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;

	text-align: center;

	user-select: none;
}

.base-button.small{
	min-width: 47px;
	padding: 0 10px;
}

.base-button:active{
	background: #cdcdcd;
}

.base-button.underline{
	background: none;
	border: none;
	color: #0075ff;
	text-decoration: underline;
}

.base-button.underline:active{
	color: #00499d;
}
</style>