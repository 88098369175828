<template>
	<div class="tab-fixed-attenders">
		<div v-if="fixedAttenders.length > 0">
			<div class="fixed-attender"
				v-for="(attender, index) in fixedAttenders"
				:key="`fixed_attender_${index}`"
				v-bind:class="{
					second : index % 2 == 0
				}">
				<div class="fixed-attender__name">
					{{attender.firstName}}
				</div>
				<div class="fixed-attender__delete-button" @click="() => {delFixedAttender(index)}">
					<span class="iconify" data-icon="uil:times" data-width="25" data-height="25"></span>
				</div>
			</div>
		</div>
		<div v-else class="fixed-attenders__empty-list">
			No attenders
		</div>
		<div class="fixed-attenders__tools">
			<div class="tools__new-attender-buttons" v-if="addAttenderIsOpen">
				<b-input placeholder="Type" ref="new__attender" v-model="futureFixedAttender"></b-input>
				<div class="tools__buttons-container">
					<b-button type="is-info" @click="addFixedAttender">Save</b-button>
					<b-button type="is-info is-light" @click="closeAddAttender">Cancel</b-button>
				</div>
			</div>
			<b-button type="is-info" v-else @click="openAddAttender">Add</b-button>
		</div>
	</div>
</template>

<script>
import {Button, Input} from 'buefy'
import BaseButton from '../../inputs/BaseButton.vue';

export default {
	name: 'TabFixedAttenders',
	components: {
		Button,
		Input,
		BaseButton,
	},
	model:{
		prop: 'fixedAttenders',
		event: 'update:fixedAttenders',	
	},
    props: {
		fixedAttenders: {
			type: Array,
			required: true,
		}
    },

	data() {
		return {
			addAttenderIsOpen: false,
			futureFixedAttender: "",
		};
	},

	methods: {

		openAddAttender(){
			this.addAttenderIsOpen = true
		},
		
		closeAddAttender(){
			this.addAttenderIsOpen = false
		},
		addFixedAttender(){
            if (this.futureFixedAttender.length == 0) return

			this.fixedAttenders.push({firstName: this.futureFixedAttender})
            this.futureFixedAttender = ""
            this.$refs["new__attender"].focus()
        },
		delFixedAttender(index){
			let attenders = this.fixedAttenders.filter((_,attenderIndex) => attenderIndex != index)
			this.$emit('update:fixedAttenders', attenders);
		},
	},
};
</script>

<style scoped>
.tab-fixed-attenders{
    overflow-y: auto;
	height: 100%;
}
.fixed-attender{
    
    font-size: 14px;
    display: grid;
    grid-template-columns: calc(100% - 31px - 10px) 31px;
    grid-column-gap: 10px;
}

.fixed-attender__name{
    padding: 7px 9px;
}

.fixed-attender__delete-button{
    display: flex;
    justify-content: center;
    align-items: center;
}

.fixed-attender.second{
    background: #dbdbdb;
}

.fixed-attenders__empty-list{
    display: flex;
    justify-content: center;
    align-items: center;
    color:grey
}

.fixed-attenders__tools{
    margin-top:10px;
	padding: 2px;
}

input {
    width: calc(100% - 12px - 2px);
    padding: 6px 6px 7px 6px;
    font-size: 16px;
    border-radius: 2px;
    border: 1px solid rgb(68, 68, 68);
}

input:focus{
    outline: none;
}

.tools__activate-button	{
	background: lightgray;
    border-radius: 5px;
    padding: 4px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    user-select: none;
}


.tools__buttons-container{
    margin-top: 10px;
    width: 210px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;
}

.tools__buttons-container > * {
    display: flex;
    justify-content:center;
    align-items: center;
    padding: 4px 7px;
        -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently */
}

.tools__buttons-container > .save-button{
    border-radius: 4px;
    background: lightgray;
}

.tools__buttons-container > .cancel-button{
    text-decoration: underline;
    color: #0075ff;
}


</style>