<template>
	<div class="tab-properties">

		<!-- Description -->

		<TextareaInput v-model="form.description" 
			placeholder="Description" height="60px"/>

		<!-- Host -->

		<div class="tab-properties__labeled-properties">
			<div class="dropbutton__label">Host</div>
			<b-select v-model="form.hostId" expanded>
                <option
                    v-for="host in hosts"
                    :value="host.id"
                    :key="`host_${host.id}`">
                    {{ host.name }}
                </option>

			</b-select>
			<!-- <DropButton :items="hosts" :current="form.hostIndex" :setter="setHostIndex"/> -->
		</div>

		<!-- Price level -->

		<div class="tab-properties__labeled-properties">
			<div class="dropbutton__label">Price level</div>
			<!-- <DropButton :items="priceLevels" :current="form.priceLevelIndex" :setter="setPriceLevelIndex"/> -->
			<b-select v-model="form.priceLevelId" expanded>
                <option
                    v-for="pl in priceLevels"
                    :value="pl.id"
                    :key="`pl_${pl.id}`">
                    {{ pl.name }}
                </option>
			</b-select>
		</div>

		<!-- Rating -->

		<div class="tab-properties__labeled-properties">
			<div class="dropbutton__label">Ratings</div>
			<b-select v-model="form.ratingId" expanded>
                <option
                    v-for="rating in ratings"
                    :value="rating.id"
                    :key="`pl_${rating.id}`">
                    {{ rating.name }}
                </option>
			</b-select>
			<!-- <DropButton :items="ratings" :current="form.ratingIndex" :setter="setRatingIndex"/> -->
		</div>

		<!-- Max attenders -->

		<div class="tab-properties__labeled-properties">
			<div class="dropbutton__label">Max attenders</div>
			<b-numberinput v-model="form.maxAttenders" controls-alignment="right" min="0"></b-numberinput>
			<!-- <MaxAttendersInput class="rightly" v-model="form.maxAttenders"
			:form="form" name="maxAttenders"/> -->
		</div>

		<!-- Enable wait list and color selector -->

		<div class="tab-properties__multi-property">
			<b-checkbox v-model="form.isWaitingListEnabled">Enable wait list</b-checkbox>
			<SlotColorPicker v-model="cell" :slotTitle="form.title"/>
		</div>
	</div>
</template>

<script>
import SlotSettingsFormInstance from './formInstance.js'
import {Field, Select} from 'buefy'

export default {
	name: 'TabProperties',
	components: {
		Field,
		Select,
		DropButton: () => import('../../inputs/DropButton.vue'),
		TextareaInput: () => import('../../inputs/TextareaInput.vue'),
		SlotColorPicker: () => import('../../inputs/SlotColorPicker.vue'),
		MaxAttendersInput: () => import('../../inputs/MaxAttendersInput.vue'),
	},
	computed: {
		hosts(){
			let hosts = this.$store.getters.calendarHosts
			this.form.updateHostIndex(hosts)
			return hosts
		},
		ratings(){
			let ratings = this.$store.getters.calendarRatings
			this.form.updateRatingIndex(ratings)
			return ratings
		},
		priceLevels(){
			let levels = this.$store.getters.calendarPriceLevels
			this.form.updatePriceLevelIndex(levels)
			return levels
		},
		cell:{
			get(){
				return {
					mainColor: this.form.cellBackgroundColor,
					textColor: this.form.cellTextColor,
				}
			},
			set(newValue){
				this.form.cellBackgroundColor = newValue.mainColor
				this.form.cellTextColor = newValue.textColor
			}
		},
	},
	model:{
		prop: 'form',
		event: 'update:form',	
	},
    props: {
		form: {
			type: SlotSettingsFormInstance,
			required: true,
		},
		disableList: {
			type: Array,
		}
    },

	methods: {
		setHostIndex(index){
			this.form.hostIndex = index
			this.form.updateHostId(this.hosts)
		},
		setRatingIndex(index){
			this.form.ratingIndex = index
			this.form.updateRatingId(this.ratings)
		},
		setPriceLevelIndex(index){
			this.form.priceLevelIndex = index
			this.form.updatePriceLevelId(this.priceLevels)
		},
	},
};
</script>

<style scoped>

.tab-properties{
	display: flex;
	flex-direction: column;
	gap: 10px;
	height: 100%;
}

.tab-properties__labeled-properties{
	height: 40px;
	display: grid;
	grid-template-columns: 118px calc(100% - 16px - 118px);
	grid-column-gap: 16px;
}

.tab-properties__labeled-properties > .dropbutton__label{
	display: flex;
	align-items: center;
}

.tab-properties__multi-property{
	display: grid;
    grid-template-columns: calc(100% - 16px - 106px) 106px;
    grid-column-gap: 16px;
}

.tab-properties__multi-property > .checkbox, .tab-properties__multi-property > .dropbutton__label {
	display: flex;
	align-items: center;
}

.rightly{
	display: flex;
	justify-content: flex-end;
}

input[type='checkbox']{
    height: 18px;
    width: 18px;
}
</style>