<template>
	<div class="wallpaper-editor">
		<vue-final-modal v-model="isOpen" @click-outside="closeEditor">
			<div class="modal__window">
				<div class="upload__background" v-bind:style="{'padding' : '0', }">
					<div class="title">Crop photo</div>
					<vue-croppie 
						ref="croppieRef"

						:enableOrientation="true"
						:enableResize="false"
						:showZoomer="false"
						:boundary="getBoundary()"
						:viewport="getViewport()">
					</vue-croppie>
					<div class="submit_buttons_cont" >
						<div class="save__button" @click="croppPhoto">
							Upload
						</div>
						<div class="cancel__button" @click="closeEditor"> 
							Cancel
						</div>
					</div>
				</div>
			</div>
		</vue-final-modal>
	</div>
</template>

<script>
import { cssVh, cssVw } from '../../scripts/cssUnits';

export default {
	name: 'WallpaperEditor',
	
	model: {
		prop: 'wallpapers',
		event: 'update:wallpapers',
	},

	props: {
		wallpapers: {
			type: Array,
			required: true,
		},
		photoUrl: {
			type: String,
			required: true,
		},
		isOpen: {
			type: Boolean,
			required: true,
		},
		closer: {
			type: Function,
			required: true,
		}
	},	
	watch: {
		photoUrl(newValue){
			this.$refs.croppieRef.bind({
                url: newValue,
            })
		}
	},

	methods: {
		closeEditor(){
            this.closer()
		},

        getBoundary(){
            let height = cssVh * 83
            let width = cssVw * 100

            return { width: width, height: height }
        },

        getViewport(){
            let height = cssVh * 83
            let width = height / 16 * 9

            return { width: width, height: height }
        },

        croppPhoto(originalPhoto){
            let options = {
                format: 'jpeg', 
            }
            this.$refs.croppieRef.result(options, (output) => {
                let cropped = output;
                let wallpaper = {
                    cropped : cropped,
                    origin : originalPhoto,
                    isPressed : false
                }
                this.wallpapers.push(wallpaper)
            });

            this.closeEditor()
        },
	},
};
</script>

<style scoped>
.modal__window{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.upload__background{
    background: white;
    min-height: 100%;
}

.upload__background > .title{
    font-size: 20px;
    text-align: center;
    padding: 10px;
}

.submit_buttons_cont{

    padding: 20px;
    padding-top: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;
    background: white;
    height: 40px;
}

.save__button{
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    height: 40px;
    background: #0075ff;
    border-radius: 7px;
    color: white;

    font-size: 20px;
    font-weight: bold;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently */
}

.cancel__button{
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    height: 40px;
    color: #0075ff;
    text-decoration: underline;
}
</style>